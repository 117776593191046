<template>
  <section class="content">
    <div class="home" v-html="page.main_text"></div>
    <div class="block top">
      <insurance-header :page="insurancePage" v-if="insurancePage"></insurance-header>

      <router-link class="secondary shadow" to="/faqs">
        <span class="secondary-wrap">
          <font-awesome-icon class="icon" icon="question" />
          <span class="secondary-label">FAQs</span>
        </span>
      </router-link>
    </div>
    <div class="block mid">
      <healthcare-header :page="healthcarePage" v-if="healthcarePage"></healthcare-header>
      <router-link class="secondary shadow" to="/regs">
        <span class="secondary-wrap">
          <font-awesome-icon class="icon" icon="book" />
          <span class="secondary-label">Police Quick Regs</span>
        </span>
      </router-link>
    </div>
    <div class="grid" v-if="countdown">
      <featured-elem :article="latestArticle"></featured-elem>
      <count-down :value="countdown"></count-down>
    </div>
    <latest-elem :articles="followingArticles" :limit="2"></latest-elem>
  </section>
</template>

<script>
import { mapState } from "vuex"
import api from "@/services/api"

import CountDown from "@/components/CountDown.vue"
import FeaturedElem from "@/components/Featured.vue"
import InsuranceHeader from "@/components/InsuranceHeader.vue"
import HealthcareHeader from "@/components/HealthcareHeader.vue"
import LatestElem from "@/components/Latest.vue"

export default {
  components: {
    CountDown,
    FeaturedElem,
    InsuranceHeader,
    HealthcareHeader,
    LatestElem
  },
  data() {
    return {
      insurancePage: null,
      healthcarePage: null,

      countdown: ""
    }
  },
  metaInfo() {
    return {
      title: this.$store.state.page.title || "South Yorkshire Police Federation",
      meta: [
        { name: "description", content: this.$store.state.page.meta_desc },
        { name: "keywords", content: this.$store.state.page.meta_keywords }
      ]
    }
  },
  computed: {
    ...mapState(["page", "articles"]),
    latestArticle() {
      if (!this.articles) return null
      if (!this.articles.length) return null

      return this.articles[0]
    },
    followingArticles() {
      if (!this.articles) return null
      if (!this.articles.length) return null

      if (this.countdown) return this.articles.slice(1)
      else return this.articles
    }
  },
  created() {
    api.getHomePage().then(() => {
      if (this.page.countdown_enabled)
        var x = setInterval(() => {
          var countDownDate = new Date(this.page.countdown_date * 1000)

          if (this.page.countdown_time) {
            var cdhours = parseInt(this.page.countdown_time.split(":")[0])
            var cdmins = parseInt(this.page.countdown_time.split(":")[1])
            countDownDate.setHours(cdhours)
            countDownDate.setMinutes(cdmins)
          }

          countDownDate.setSeconds(0)

          countDownDate = countDownDate.getTime()

          // Get today's date and time
          var now = new Date().getTime()

          // Find the distance between now and the count down date
          var distance = countDownDate - now

          // Time calculations for days, hours, minutes and seconds
          var days = Math.floor(distance / (1000 * 60 * 60 * 24))
          var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
          var seconds = Math.floor((distance % (1000 * 60)) / 1000)

          // Display the result in the element with id="demo"
          this.countdown =
            days +
            ":" +
            hours.toString().padStart(2, "0") +
            ":" +
            minutes.toString().padStart(2, "0") +
            ":" +
            seconds.toString().padStart(2, "0")

          // If the count down is finished, write some text
          if (distance < 0) {
            clearInterval(x)
            this.countdown = ""
          }
        }, 1000)
    })
    api.getPageByItemID("nL7MIVtBq").then(res => (this.insurancePage = res))
    api.getPageByItemID("2_wGuGXUE").then(res => (this.healthcarePage = res))
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.secondary {
  display: none;
}

@media screen and (max-width: 500px) {
  .latest .img {
    height: 25rem;
  }
}

@media screen and (min-width: 1050px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr;
  }

  .block {
    display: grid;
    padding: 2rem;
    grid-template-columns: 1fr;
    grid-gap: 3rem;
  }
  .secondary {
    display: flex;
    align-items: center;
    background: #febd51;
    background: linear-gradient(90deg, #febd51 0%, #f7ce49 100%);
    color: #203165;
    border-radius: 0.75rem;
    padding: 3rem;
    text-align: center;
    font-size: 3rem;
    line-height: 1.2;
    transition: all 0.3s;
  }
  .secondary:hover {
    transform: scale(1.02);
  }
  .secondary svg {
    font-size: 5rem;
    color: #334272;
    margin: 1.5rem;
  }
  .secondary span {
    display: block;
    width: 100%;
  }
}

@media screen and (min-width: 1250px) {
  .grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  .block {
    grid-template-columns: 2fr 1fr;
  }
}
</style>

<template>
  <router-link :to="article.url" class="latest shadow" v-if="article">
    <span :style="{ backgroundImage: 'url(' + article.image + ')' }" aria-label="title" class="img"></span>
    <span class="item-content">
      <span class="title">{{ article.name }}</span>
      <span class="summary">{{ article.description }}</span>
      <span class="link">Read more</span>
    </span>
  </router-link>
</template>

<script>
export default {
  props: ["article"]
}
</script>

<style scoped>
.item {
  background: white;
  margin: 1.5rem;
  display: block;
  border-radius: 0.75rem;
  padding-top: 1rem;
}
.title {
  display: block;
  padding: 1rem 2rem;
  font-size: 1.8rem;
  font-weight: bold;
  color: #203165;
  line-height: 1.4;
}
.summary {
  display: block;
  padding: 0 2rem 1rem 2rem;
  font-size: 1.2rem;
}
.link {
  display: block;
  font-weight: bold;
  color: #db411d;
  padding: 0 2rem 2rem 2rem;
  font-size: 1.6rem;
}

.img {
  background-position: center center;
  background-size: cover;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  width: 100%;
  display: none;
  height: 35rem;
}

@media screen and (min-width: 1600px) {
  .img {
    position: relative;
    border-top-left-radius: 0.75rem;
    border-bottom-left-radius: 0.75rem;
    border-top-right-radius: 0;
    height: auto;
    display: block;
  }
}

@media screen and (min-width: 1050px) {
  .summary {
    font-size: 1.5rem;
  }
}
</style>

<template>
  <div class="countdown shadow">
    <font-awesome-icon class="icon" icon="stopwatch" />
    <!-- <div class="timer">00:14:10:38</div> -->
    <div class="timer">{{ value }}</div>
    <div class="label">remaining until new shift patterns take effect</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex"

export default {
  props: ["value"],
  computed: {
    ...mapState(["page"]),
    ...mapGetters(["isHomePage"])
  }
}
</script>

<style scoped>
.countdown {
  background: #db411d;
  background: linear-gradient(90deg, rgba(219, 65, 29, 1) 0%, rgba(242, 108, 76, 1) 100%);
  text-align: center;
  padding: 3rem;
  padding-bottom: 4rem;
  color: white;
  margin: 1.5rem;
  border-radius: 0.75rem;
}
.icon {
  font-size: 3rem;
}
.timer {
  font-size: 4rem;
}
.label {
  font-size: 2rem;
  padding: 0 3rem;
  line-height: 2.5rem;
}
</style>
